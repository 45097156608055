import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    props:true
  },
  {
    path: "/schedule",
    name: "Schedule",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/schedule.vue")
  },
  {
    path: "/oom/:season",
    name: "Oom",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/oom.vue"),
      props: true
  },
  {
    path: "/reports",
    name: "reports",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/reports.vue"),
    props: true
  },
  {
  path: "/live",
  name: "live",
  component: () =>
    import(/* webpackChunkName: "about" */ "../views/live.vue"),
  },
  {
    path: "/membership",
    name: "membership",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    },
    {
      path: "/tour-policies-2022",
      name: "tour-policies-2022",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/single.vue"),
      },
  {
    path: "/news",
    name: "News",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/news.vue")
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/changing-lives-program",
    name: "changing-lives-program",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/wtbapro",
    name: "wtbapro",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/contact",
    name: "contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/igt-newsletter-signup",
    name: "igt-newsletter-signup",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/:id",
    name: "news story",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsStory.vue"),
    props: true
  },
  { 
    path: '/:catchAll(.*)', 
    name: 'NotFound'
  }
];

const router = new VueRouter({
  routes,
  mode:'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
});

router.beforeEach((to, from, next) => {
  /* must call `next` */
  // let route_records = routes.getRoutes();
  // console.log(route_records);
  let route_check = router.resolve(to.path);
  if (route_check.resolved.matched.length > 0) {
    //the route is exists.
    return next();
  } else {
    // console.log("resolved");
    // console.log(route_check);
    // console.log("to");
    // console.log(to);
    // console.log("from");
    // console.log(from);
    //the route does not exists.
    // let slug_name = to.path.split("/").pop();
    let slug_name = to.path;
    if (slug_name.endsWith("/")) {
      slug_name = slug_name.slice(0, -1);
    }
    slug_name = slug_name.split("/").pop();
    let route_options = {
      // path: "/" + element.slug,
      path: to.path,
      name: slug_name,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/single.vue")
    };
    // console.log("route_options");
    // console.log(route_options);
    router.addRoute(route_options);
    // next();
    next({ path: to.path });
    // return next();
  }
});

export default router;
