<template>
  <div>
    <b-row v-if="home === true" :class="postsRow">
      <b-col lg='6' md='12' cols='12'>
        <b-img
          :class="postImg"
          :src="posts[0].better_featured_image.source_url"
          fluid
          alt="Responsive image"
        />
        <div class='featBlurb'>
          <h1>
            {{ posts[0].title.rendered }}
          </h1>
          <span v-html="posts[0].excerpt.rendered"></span>
          <b-nav-item class='mainBtn' :to="'/' + posts[0].slug">
            Full Story
          </b-nav-item>
        </div>
      </b-col>
      <b-col lg='6' md='12' cols='12'>
        <b-row>
          <b-col lg='6' md='6' cols='12'
            id="mobilePost"
            v-for="(posts, index) in posts.slice(0, 8)"
            :key="index"
          >
            <b-nav-item :to="'/' + posts.slug">
              <div class="outerImg">
                <b-img
                  :class="postImg"
                  :src="posts.better_featured_image.source_url"
                  fluid
                  alt="Responsive image"
                />
              </div>
              <div class="miniWrap">
                <h3 class="homeNews">
                  {{ posts.title.rendered }}
                </h3>
              </div>
            </b-nav-item>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col lg='3' md='3' cols='12'
        id="mobilePost"
        v-for="(posts, index) in posts.slice(0,4)"
        :key="index"
      >
        <b-nav-item :to="'/' + posts.slug">
          <div class="outerImg">
            <b-img
              :class="postImg"
              :src="posts.better_featured_image.source_url"
              fluid
              alt="Responsive image"
            />
          </div>
          <div class="miniWrap">
            <h3 class="homeNews">
              {{ posts.title.rendered }}
            </h3>
          </div>
        </b-nav-item>
      </b-col>
    </b-row>
    <div class='moreLink'>
      <b-button class='moreNews' to="/news">View All News</b-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "posts",
  props: ["home"],
  data() {
    return {
      posts: [],
      postsRow: "posts",
      postImg: "postImg",
    };
  },
  mounted() {
    axios //add API Call
      .get(process.env.VUE_APP_WPAPI_URL + "wp/v2/posts") // Page number changed on each page based on JSON page id you are calling
      .then((response) => (this.posts = response.data));
  },
};
</script>

<style scoped>
#mobilePost > li {
  list-style: none;
}
#mobilePost > li > a{
  padding: 0px;
}
.outerImg {
  height: 200px;
  overflow: hidden;
}
.featBlurb{
  background-color: #2f8d3e;
  color: white;
  padding: 10px 15px;
}
.mainBtn>.nav-link {
  color: white !important;
  background-color: #2f8d3e;
  width: 125px;
  text-align: center;
  border: 1px solid white;
}
.mainBtn>.nav-link:hover {
  color: #2f8d3e!important;
  background-color: white;
  width: 125px;
  text-align: center;
  border: 1px solid white;
}
.firstImg {
  width: 100%;
}
.miniWrap {
  margin-top: -60px;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.homeNews {
  height: 90px;
  text-align: left;
  position: relative;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0.7368421053em 1.0526315789em 0.6315789474em;
  font-weight: bold;
  font-size: 1.1875em;
  line-height: 1.2em;
  width: 100%;
  background-color: rgba(47, 141, 62, 0.9);
  color: white;
  margin-top: -84px;
}
.moreNews{
  display: inline-block;
  color: #FFFFFF;
  font-family: "OpenSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 0.875em;
  line-height: 1;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  border: 1px solid #FFFFFF;
  padding: 1.2857142857em 2.8571428571em;
  text-transform: uppercase;
  font-weight: bold;
  background: #2F8D3E;
  border-radius: 0px;  
}
.moreLink{
  text-align: center;
  padding-top: 60px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 1024px) {
  .homeNews {
    font-size: 1em;
  }
}
@media only screen and (max-width: 768px) {
  .homeNews{
    height: 90px;
  }
    #mobilePost {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .moreLink{
    padding: 30px;
}
}
@media only screen and (max-width: 480px) {
.featBlurb>h1{
  font-size: 1.5rem;
}
}

</style>
