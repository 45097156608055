<template>
  <div style="text-align:center;">
    <span>
      <div id="fb-root"></div>
      <script
        type="application/javascript"
        async
        defer
        crossorigin="anonymous"
        src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v5.0"
      ></script>
      <div
        class="fb-page facebookDesktop"
        :data-href="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_NAME+'/?rf=735464763131634'"
        data-tabs="timeline"
        data-width="800"
        data-height="400"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          :cite="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_NAME+'/?rf=735464763131634'"
          class="fb-xfbml-parse-ignore"
        >
          <a
            :href="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_NAME+'/?rf=735464763131634'"
            >IGT Tour</a
          >
        </blockquote>
      </div>
      <div
        class="fb-page facebookMobile"
        :data-href="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_NAME+'/?rf=735464763131634'"
        data-tabs="timeline"
        data-height="825"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          :cite="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_NAME+'/?rf=735464763131634'"
          class="fb-xfbml-parse-ignore"
        >
          <a
            :href="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_NAME+'/?rf=735464763131634'"
            >IGT Tour</a
          >
        </blockquote>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  name: "facebookFeed",
  props: ["develop", 'config'],
};
</script>

<style scoped>

::v-deep iframe {
  width: 100%!important;
  min-width: 100%!important;
}
::v-deep ._2p3a {
  min-width: 100%!important;
  width: 100%!important;
}
::v-deep .fb_iframe_widget {
  display: inline-block;
  position: relative;
  width: 100%!important;
}
#twitter-widget-0 {
  height: 500px;
}
::v-deep ._2p3a {
  width: 100%!important;
  min-width: 100%!important;
}
::v-deep .fb_iframe_widget span {
  display: inline-block;
  position: relative;
  text-align: justify;
  width: 100%!important;
}

@media only screen and (max-width: 500px) {
  .facebookDesktop {
    display: none;
  }
}
@media only screen and (min-width: 501px) {
  .facebookMobile {
    display: none;
  }
}
</style>
