<template>
  <div id="reportTable">
    <div class="title" v-if="home === false">
      <h3>{{ data.full_name }} - {{ title }}</h3>
      <p>
        {{ data.course_dates
        }}<span
          v-if="
            tablePick === 'Score' ||
              tablePick === 'Final' ||
              tablePick === 'Live'
          "
        >
          <br />
          Last Real Time Update - {{localTime}} <span v-if="tablePick === 'Score' || tablePick === 'Live'">{{ data.last_scores_update_date }}</span><span v-if="tablePick === 'Final'">{{data.last_updated_date}}</span> Ain Sokhna Time - 
          <span v-if="tablePick === 'Score' || tablePick === 'Live'">{{ data.last_scores_update_time }} {{ data.last_scores_update_date }}</span><span v-if="tablePick === 'Final'">{{ data.last_updated_time }} {{ data.last_updated_date }}</span></span>
        <br>
        Projected Finish Time - {{data.pft_time}}
      </p>
      <p
        v-if="
          tablePick === 'Live' ||
            (tablePick === 'Score' && data.live_scoring === 'Y')
        "
        style="text-align: right;"
      >
        *Did not start from 1st Hole.
      </p>
    </div>
    <table>
      <thead>
      </thead>
      <tbody>
        <template v-if="tablePick === 'Entries'">
          <Entries 
            :data="data"
            :home="home"
            :title="title"
            :config="config"
            :team="team"
            :match="match"
            :code="code"
            :season="season"
          />
        </template>
        <template v-if="tablePick === 'Draw'">
          <Draw 
            :data="data"
            :home="home"
            :title="title"
            :config="config"
            :team="team"
            :match="match"
            :code="code"
            :season="season"
          />
        </template>
        <template v-if="tablePick === 'Score' || tablePick === 'Live'">
          <Score 
            :data="data"
            :home="home"
            :title="title"
            :config="config"
            :team="team"
            :match="match"
            :code="code"
            :season="season"
          />
        </template>
        <template v-if="tablePick === 'Final'">
          <Results 
            :data="data"
            :home="home"
            :title="title"
            :config="config"
            :team="team"
            :match="match"
            :code="code"
            :season="season"
          />
        </template>
        <template v-for="(tabledata, index) in Eds">
          <tr @click.stop="rowClicked(index)" :key="index">
          </tr>
          <tr
            @click.stop="rowClicked(rowClicked === -1)"
            v-if="index === clickedRow"
            class="tr-kids"
            :key="index + Math.random()"
          >
            <td id="reportCard" colspan="11">
              <PlayerReportCard
                :data="playerCardData"
                :config="config"
                :actualRound="data.pft_round"
                :roundsPlayed="data.pft_round"
              />
            </td>
          </tr>
        </template>
        <template>
          <div class="LiveScoreMess" v-if="data.live_scoring_mess">
            <p class="LiveScoreMessPara">{{data.live_scoring_mess}}</p>
          </div>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import PlayerReportCard from "@/components/playerReportCard.vue";
import Entries from "@/components/entries.vue";
import Draw from "@/components/draw.vue";
import Score from "@/components/scoresOnly.vue";
import Results from "@/components/Results.vue";
export default {
  name: "reportTable",
  props: ["data", "previousData", "home", "title", "updown", 'config','match','team'],
  components: { PlayerReportCard, Entries, Draw, Score, Results },
  data() {
    return {
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: [],
    };
  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.tablePick === "Score" && this.clickedRow !== index) this.clickedRow = index;
      else this.clickedRow = -1;
    },
    playerCard: function(event) {
      var cardID = event.target.getAttribute("title");
      return (
        (this.cardID = cardID),
        axios
          .get(
            process.env.VUE_APP_TIC_BASE + process.env.VUE_APP_ID + '/' +
              this.season +
              "/" +
              this.season +
              "-" +
              this.code +
              "-cards-" +
              this.cardID +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then((response) => (this.playerCardData = response.data))
      );
    },
  },
  computed: {
    localTime: function (time, local){
      if(this.tablePick === 'Final'){
      time = this.data.last_updated_time.split(':')
      local = time[0]-1 + ':' + time[1]
      }
      else {
      time = this.data.last_scores_update_time.split(':')
      local = time[0]-1 + ':' + time[1] + ':' + time[2]
      }
      return local
    },
    tablePick: function(picked) {
      if (this.title === "Tournament Entries") picked = "Entries";
      else if (this.title.includes("Draw")) picked = "Draw";
      else if (
        this.title.includes("Scores") ||
        this.title.includes("Scoreboard")
      )
        picked = "Score";
      else if (this.title.includes("Final") || this.title.includes("Statistics")) picked = "Final";
      else if (this.title.includes("Live")) picked = "Live";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    Eds(reportData) {
      var ud = this.ud;
      if (this.home === false) {
        if (this.title === "Tournament Entries")
          reportData = this.data.entries.entries_entry;
        return reportData;
      } else {
        if (this.title === "Tournament Entries")
          reportData = this.data.entries.entries_entry.slice(0, ud);
        return reportData;
      }
    },
    ud(way) {
      if (this.updown === false) way = 4;
      else way = 5;
      return way;
    },
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
p.LiveScoreMessPara {
  color: #fff;
  padding-top: 15px;
}
.LiveScoreMess {
  background: red;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  margin: auto;
  left: 0;
  right: 0;
}
span.nameTourn {
  color: #519c1f;
  font-size: 15pt;
  font-weight: 500;
}
td.exmptRow {
  color: #fff;
  background-color: #519c1f;
}
span.ScoreName {
  color: #519c1f;
  font-size: 15pt;
}
.drawPos{
  max-width: 100%!important;
  text-align: -webkit-center!important;
  margin-top: 0px!important;
  padding: 7px!important;
}
.title {
  text-align: center;
  background-color: #ffffff;
  color: #2F8D3E;
}
.title > h3 {
  padding-top: 8px;
  margin-bottom: 0px;
  font-size: 24px;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  position: sticky;
  top: 0;
  background-color: #2F8D3E;
  color: white!important;
      padding: 0.75rem;
    border-top: 1px solid #dee2e6;
}
table {
  width: 100%;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: center;
}
td:nth-child(3) {
  text-align: center;
}
.odd {
  background-color: white !important;
}
.even {
  background-color: #dfe1e6 !important;
}
tr:nth-child(even) {
  background-color: #dfe1e6;
}
td{
    padding: 0.75rem;
    vertical-align: top;
    
    font-size: 13pt;
}

.catD{
  background-color: #519c1f !important;
  color: #fff;
  font-size: 19px;
  padding: 6px;
}
td.posnum {
  padding: 5px;
  padding-top: 8px;
  padding-bottom: 12px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
  background-color: #2F8D3E;
  color: #fff;
}
td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
  background-color: #2F8D3E;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px !important;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}
@media only screen and (max-width: 768px) {
  .title > h3 {
    font-size: 13px !important;
  }
  .title > p {
    font-size: 12px !important;
  }
  th {
    font-size: 13px !important;
  }
  td {
    font-size: 13px !important;
  }
}
@media only screen and (max-width: 480px) {
  .mobHide{
    display: none;
  }
}

</style>
