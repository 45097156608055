var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tab',{attrs:{"title":this.TourName + _vm.liveOn}},[(
      this.reportURL.indexOf('tmentry') !== -1 ||
        this.reportURL.indexOf('tmdraw') !== -1 ||
        this.reportURL.indexOf('tmscores') !== -1 ||
        this.reportURL.indexOf('tmresult') !== -1 ||
        this.reportURL.indexOf('tmoomt') !== -1
    )?_c('div',[_c('ReportTable',{attrs:{"data":_vm.data,"previousData":_vm.previousData,"home":_vm.home,"title":_vm.title,"config":_vm.config,"updown":_vm.updown,"team":_vm.teamEvent,"match":_vm.match}}),_c('b-nav',{staticClass:"fullListings",attrs:{"small":""}},[(_vm.title === 'Tournament Entries' || _vm.title === 'Final Result' || _vm.title.includes('Draw'))?_c('b-button',{staticClass:"Flisting-btn-more left",on:{"click":_vm.upDown}},[_c('span',{staticClass:"black"}),(_vm.updown === false)?_c('span',[_vm._v(" More")]):_vm._e(),(_vm.updown === true)?_c('span',[_vm._v(" Less")]):_vm._e()]):_vm._e(),(_vm.title === 'Tournament Entries' || _vm.title === 'Final Result')?_c('b-nav-item',{staticClass:"Flisting-btn",attrs:{"to":{
          name: 'reports',
          query: { url: _vm.config.VUE_APP_API_FULL_LIST_URL, id: _vm.Year, code: _vm.courseCode, title: 'Tournament Entries' },
        }}},[_c('span',{staticClass:"black"},[_vm._v("| "),_c('span',{staticClass:"FullListing"},[_vm._v("Full listing")])])]):_vm._e(),(_vm.title.includes('Draw'))?_c('b-nav-item',{staticClass:"left drawListing-btn",attrs:{"to":{
          name: 'reports',
          query: { url: _vm.reportURL, id: _vm.Year, code: _vm.courseCode, title: 'Draw' },
        }}},[_c('span',{staticClass:"black"},[_vm._v(" |")]),_vm._v(" Draw "),_c('span',{staticClass:"black"},[_vm._v("|")])]):_vm._e(),(_vm.title.includes('Draw'))?_c('b-nav-item',{staticClass:"right drawListing-btn ",attrs:{"to":{
          name: 'reports',
          query: {
            url:
              _vm.config.VUE_APP_API_FULL_LIST_URL,
            id: _vm.Year,
            code: _vm.courseCode,
            title: 'Tournament Entries'
          },
        }}},[_vm._v(" Full Listing")]):_vm._e(),(_vm.title === 'Score')?_c('b-nav-item',{staticClass:"lef Flisting-btnt",attrs:{"to":{
          name: 'reports',
          query: { url: _vm.reportURL, id: _vm.Year, code: _vm.courseCode,title: 'Score' },
        }}},[_vm._v("Score |")]):_vm._e(),(_vm.title === 'Score')?_c('b-nav-item',{staticClass:"right",attrs:{"to":{
          name: 'reports',
          query: {
            url:
              _vm.config.VUE_APP_API_FULL_LIST_URL,
            id: _vm.Year,
            code: _vm.courseCode,
            title: 'Tournament Entries'
          },
        }}},[_vm._v("| Full Listing")]):_vm._e()],1)],1):(this.reportURL.indexOf('tmlsmon') !== -1)?_c('div',[_c('ReportTable',{attrs:{"data":_vm.data,"previousData":_vm.previousData,"home":_vm.home,"title":_vm.title,"config":_vm.config,"updown":_vm.updown,"team":_vm.teamEvent}}),_c('div',[_c('b-nav',{staticClass:"fullListings",attrs:{"small":""}},[_c('b-button',{staticClass:"Flisting-btn-more left",on:{"click":_vm.upDown}},[_vm._v("|"),(_vm.updown === false)?_c('span',[_vm._v(" More")]):_vm._e(),(_vm.updown === true)?_c('span',[_vm._v(" Less")]):_vm._e()]),_c('b-nav-item',{staticClass:"left three Flisting-btn",attrs:{"to":{
            name: 'reports',
            query: {
              url: _vm.reportURL,
              id: _vm.Year,
              code: _vm.courseCode,
              title: 'Live Score Monitor',
            },
          }}},[_vm._v("| Live Score ")]),_c('b-nav-item',{staticClass:"center three Flisting-btn",attrs:{"to":{
            name: 'reports',
            query: {
              url: _vm.reportURL,
              id: _vm.Year,
              code: _vm.courseCode,
              title: 'Live Score Monitor',
            },
          }}},[_vm._v("| Hole By Hole ")]),_c('b-nav-item',{staticClass:"right three Flisting-btn",attrs:{"to":{
            name: 'reports',
            query: {
              url:
                _vm.config.VUE_APP_API_FULL_LIST_URL,
              id: _vm.Year,
              code: _vm.courseCode,
              title: 'Tournament Entries',
            },
          }}},[_vm._v("| Full Listing |")])],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }