<template>
    <div>
        <b-row>
            <b-col lg='6'>
            <b-embed
            type="iframe"
            aspect="16by9"
            :src="'https://www.youtube.com/embed/'+videos.items[0].id.videoId"
            allowfullscreen
          ></b-embed>
            </b-col>
        <b-col lg='6'>
        <b-row>
            <b-col class='grid' lg='4' v-for="(gallery, index) in videos.items.slice(1,10)" :key="index">
            <b-embed
            type="iframe"
            aspect="16by9"
            :src="'https://www.youtube.com/embed/'+gallery.id.videoId"
            allowfullscreen
          ></b-embed>
            </b-col>
        </b-row>
        </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name:'youTube',
    data(){
        return{
            videos:[]
        }
    },
mounted() {
    axios //add API Call
      .get(
        process.env.VUE_APP_YOUTUBE_URL + process.env.VUE_APP_YOUTUBE_KEY + "&channelId=" + process.env.VUE_APP_YOUTUBE_ID + "&part=snippet,id&order=date&maxResults=20"
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.videos = response.data;
      });
  },
}
</script>

<style scoped>
.row{
padding-bottom: 2%;
}
.grid{
  padding: 0px 3px 4px;
}
</style>
