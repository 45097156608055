<template>
  <div>
    <template v-if="currentOom !== null">
      <b-tab :title="currentOom.full_name + ' Order of Merit'">
        <b-table
          :items="currentOom.oom.oom_entry"
          :fields="fields"
          :per-page="pPage"
        >
          <template #cell(nationality)="row">
            <span v-if="row.item.pos !== 'CUT'">
              <img
                :class="flag"
                :src="
                  (config.VUE_APP_FLAG_URL + row.item.nationality) | lowercase  "
              />
            </span>
          </template>
          <template #cell(name)="row">
                {{ row.item.name }}
          </template>
          <template #cell(pos)="row">
            <span v-if="row.item.pos !== 'CUT'">
              {{ row.item.pos }}
            </span>
          </template>
        </b-table>
        <div class="more">
          <div class="flex">
            <b-button @click="upDown" class="moreBtn"
              ><span v-if="pPage === 4">More</span
              ><span v-if="pPage === 10">Less</span></b-button
            ><b-nav-item :to="'/oom/' + Year" class="fullList"
              ><span class="black">| </span> Full Listing</b-nav-item
            >
          </div>
        </div>
      </b-tab>
    </template>
    <template v-else>
      <b-tab :title="previousOom.full_name + ' Oom'">
        <b-table
          :items="previousOom.oom.oom_entry"
          :fields="fields"
          :per-page="6"
        >
          <template #cell(nationality)="row">
            <span v-if="row.item.pos !== 'CUT'">
              <img
                :class="flag"
                :src="
                  (config.VUE_APP_FLAG_URL + row.item.nationality) | lowercase
                "
              />
            </span>
          </template>
          <template #cell(name)="row">
                {{ row.item.name }}
          </template>
        </b-table>
        <div class="more">
          <div class="flex">
            <b-button @click="upDown" class="moreBtn"
              ><span v-if="pPage === 4">More</span
              ><span v-if="pPage === 10">Less</span></b-button
            ><b-nav-item :to="'/oom/' + Year" class="fullList"
              ><span class="black">| </span> Full Listing</b-nav-item
            >
          </div>
        </div>
      </b-tab>
    </template>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "oom",
  props: ["Year", "config"],

  data() {
    return {
      fields: [
        { key: "pos", label: "Pos", class: "posnum" },
        { key: "name", label: "Name", class: "leftName"},
        { key: "nationality", label: "Nat" },
        { key: "oom_value", label: "Points" },
        { key: "tournaments_played", label: "Tourns" },
      ],
      currentOom: [],
      previousOom: [],
      year: parseInt(this.Year) - 1,
      flag: "flag",
      pPage: 4,
    };
  },

  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
  methods: {
    upDown() {
      if (this.pPage === 4) this.pPage = 10;
      else if (this.pPage === 10) this.pPage = 4;
    },
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE +
          process.env.VUE_APP_ID +
          "/" +
          this.Year +
          "/" +
          this.Year +
          "-ooms-oom-" +
          process.env.VUE_APP_OOM +
          ".json?randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.currentOom = response.data;
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_ID +
            "/" +
            this.year +
            "/" +
            this.year +
            "-ooms-oom-" +
            process.env.VUE_APP_OOM +
            ".json?randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.previousOom = response.data;
      });
  },
};
</script>

<style scoped>
::v-deep .cell {
  width: 15%;
  text-align: center;
  padding: 13px;
}
::v-deep td.leftName {
  text-align: left;
}
::v-deep th.leftName {
  text-align: left;
}
::v-deep thead {
  color: white;
  background-color: #2f8d3e;
}
::v-deep td.posnum {
  background: #2f8d3e;
  width: 58px;
  padding: 10px;
  display: block;
  text-align: center;
  margin: auto;
  color: #fff;
  padding-top: 6px!important;
  padding-bottom: 6px!important;
  border-top: 1px solid #dee2e6;
}
::v-deep th {
  width: 15%;
  text-align: center;
}
.oomName {
  color: #000;
}
::v-deep td {
  text-align: center;
  padding: 0;
  font-size: 13pt;
}
::v-deep tr{
  border-top: 1px solid #dee2e6;
}
.flag {
  width: 30px;
}
::v-deep tr:nth-child(even) {
  background-color: #dee1e5;
}
.more {
  background-color: #dfe1e6;
  text-align: center;
}
.flex {
  display: inline-flex;
}
.moreBtn {
  color: #519c1f;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #dfe1e6 !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0.375rem 0.175rem;
}
.moreBtn:hover {
  color: #337ab7;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #dfe1e6 !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0.375rem 0.175rem;
}
.fullList {
  width: auto !important;
}
.fullList > a {
  color: #519c1f !important;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
}
.fullList > a:hover {
  color: #337ab7 !important;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
}
li {
  list-style: none;
}
@media only screen and (max-width: 768px) {

  ::v-deep th {
    font-size: 13px !important;
  }
  ::v-deep td {
    font-size: 13px !important;
  }
.fullList>a{
  font-size: 12px!important;
  padding-left: 0.7rem;
}.moreBtn{
  font-size: 12px!important;
}
::v-deep th:nth-child(3){
  display: none;
}
::v-deep td:nth-child(3){
  display: none;
}
}

</style>
