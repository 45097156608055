<template>
  <div class="menu">
    <div id="nav">
      <b-navbar type="dark" id="topNav" class="greenNav">
        <b-navbar-nav>
          <b-nav-item class='socialLinks' target="_blank"
            :href="'https://www.facebook.com/' + config.VUE_APP_FACEBOOK_NAME"
          >
            <font-awesome-icon class="icon" :icon="['fab', 'facebook']" />
          </b-nav-item>
          <b-nav-item class='socialLinks' target="_blank"
            :href="'https://twitter.com/' + config.VUE_APP_TWITTER_NAME"
          >
            <font-awesome-icon class="icon" :icon="['fab', 'twitter']" />
          </b-nav-item>
          <b-nav-item class='socialLinks' target="_blank"
            :href="
              'https://www.youtube.com/channel/' + config.VUE_APP_YOUTUBE_ID
            "
          >
            <font-awesome-icon class="icon" :icon="['fab', 'youtube']" />
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item class='socialLinks' :href="config.VUE_APP_PLAYER_MEMBER_URL" target="_blank"
            >MEMBERS</b-nav-item
          >
        </b-navbar-nav>
      </b-navbar>
    </div>
    <div id="nav">
      <b-navbar type="light" toggleable="xl" id="bottomNav">
        <b-navbar-nav>
          <b-navbar-brand to="/">
            <b-img
              class="siteLogo NEWLOGOTHREE"
              :src="'https://wp-igtorg.ocs-sport.com/wp-content/uploads/2021/12/IGT-Logo-Only-2022.jpg'"
            ></b-img>
          </b-navbar-brand>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item class="quick" to="/schedule">Quick Entry</b-nav-item>
        </b-navbar-nav>

        <b-navbar-toggle class="navbar-toggler collapsed border-0" type="button" data-toggle="collapse" target="nav-collapse">
            <!-- these spans become the three lines -->
            <span> </span>
            <span> </span>
            <span> </span>
        </b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item class="menuLink" to="/">HOME</b-nav-item>
            <b-nav-item class="menuLink" to="/schedule">TOURNAMENTS</b-nav-item>
            <b-nav-item class="menuLink" :to="'/' + 'membership'"
              >MEMBERSHIP 2022</b-nav-item
            >
            <b-nav-item class="menuLink" :to="'/' + 'tour-policies-2022'"
              >TOUR POLICES</b-nav-item
            >
            <b-nav-item class="menuLink" :to="'/oom/' + seasons"
              >OOM</b-nav-item
            >
            <b-nav-item
              class="menuLink"
              href="https://igtgolf.smugmug.com/"
              target="_blank"
              >GALLERY</b-nav-item
            >
            <b-nav-item class="menuLink" to="/news">NEWS</b-nav-item>
            <b-nav-item
              class="menuLink"
              href="https://all.igtgolf.info/tic/tmtic.cgi"
              target="_blank"
              >INFO CENTRE</b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Menu",
  props: ["config"],
  data() {
    return {
      menueLink: "menuLink",
      seasons: [],
      course: [],
      reports: [],
      cr: this.currentReport,
    };
  },
  methods: {
    current: function() {
      if (Array.isArray(this.reports))
        return this.reports[this.reports.length - 1].report_url;
      else return this.reports.report_url;
    },
  },
  computed: {
    filterReports: function() {
      if (Array.isArray(this.reports)) {
        return this.reports.filter(
          (reports_entry) =>
            reports_entry.report_title.includes("Tournament Entries") ||
            reports_entry.report_title.includes("Score") ||
            reports_entry.report_title.includes("Live") ||
            reports_entry.report_title.includes("Final")
        );
      } else return this.reports; 
    },
    currentReport: function() {
      if (Array.isArray(this.filterReports))
        return this.filterReports[this.filterReports.length - 1].report_url;
      else return this.filterReports.report_url;
    },
    currentTitle: function() {
      if (Array.isArray(this.filterReports))
        return this.filterReports[this.filterReports.length - 1].report_title;
      else return this.filterReports.report_title;
    },
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE +
          process.env.VUE_APP_ID +
          "/tmticx?randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.seasons = response.data.tm_params.season_code;
        this.course = response.data.code;
        this.reports = response.data.reports.reports_entry;
        this.$emit("season", { season: this.seasons });
        this.$emit("course", { course: this.course });
        this.$emit("currentReport", { currentReport: this.currentReport });
        this.$emit("currentTitle", { currentTitle: this.currentTitle });
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button.navbar-toggler.collapsed.border-0 > span {
  color: #2f8d3e;
  background: #2f8d3e;
}
/* Define the shape and color of the hamburger lines */
.navbar-toggler span {
  display: block;
  background-color: #2f8d3e;
  height: 3px;
  width: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 0.35s ease-out;
  transform-origin: center left;
}


/* top line needs a little padding */
.navbar-toggler span:nth-child(1) {
  margin-top: 0.3em;
}

/**
 * Animate collapse into X.
 */

/* top line rotates 45 degrees clockwise and moves up and in a bit to close the center of the X in the center of the button */
.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
}
/* center line goes transparent */
.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}
/* bottom line rotates 45 degrees counter clockwise, in, and down a bit to close the center of the X in the center of the button  */
.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg) ;
}


/**
 * Animate collapse open into hamburger menu
 */

/* top line moves back to initial position and rotates back to 0 degrees */
.navbar-toggler span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg) ;
}
/* middle line goes back to regular color and opacity */
.navbar-toggler span:nth-child(2) {
  opacity: 1;
}
/* bottom line goes back to initial position and rotates back to 0 degrees */
.navbar-toggler span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg) ;
}
div#nav {
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
  box-shadow: rgb(0 0 0 / 15%) 0 1px;
}
.siteLogo {
  max-width: 150px;
  text-align: center;
  float: left;
  margin: 0;
  padding-left: 30px;
  margin-top: -10px;
}

.nav-link {
  font-size: 14px !important;
}

.menuLink > .nav-link {
  color: black !important;
  font-weight: bold;
}

.quick > .nav-link {
  font-size: 16px !important;
  color: #519c1f !important;
  font-weight: bold;
}
.quick{
  padding-left: 450px;
}
.menuLink > .nav-link:hover {
  color: #519c1f !important;
}
.menuLink>.router-link-exact-active {
  background-color: white;
  color: #519c1f !important;
}
.icon {
  font-size: 23px;
}
.socialLinks>a{
  color: rgba(255, 255, 255, 0.5);
}
.socialLinks>a:hover{
  color: rgba(255, 255, 255, 1)!important;
}
#topNav {
  background-color: #2f8d3e;
}
@media only screen and (max-width: 1366px) {
.quick{
  padding-left: 150px;
}
}
@media only screen and (max-width: 1199px) {
  .quick{
  padding-left: 0px;
}
}
@media only screen and (max-width: 1024px) {
  #nav-collapse {
    padding-left: 0;
  }
}
@media only screen and (max-width: 480px) {
  .greenNav {
    display: none;
  }

  #nav-collapse {
    padding-left: 0;
  }

  #logo {
    width: 90%;
  }
}
</style>
