<!-- Nav Menu -->

<template>
  <div id="app">
    <Menu @season="setSeason" @currentReport='setCurrentReport' @course='setCourse' @currentTitle='setCurrentTitle' :config="env"/>
    <transition name="fade" appear> <router-view :key="$route.fullPath" :season='season' :course='course' :currentReport='currentReport' :currentTitle='currentTitle' :config="env"/></transition>
    <Footer :season='season' :course='course' :currentReport='currentReport' :config="env"/>
  </div>
</template>

<style>
::v-deep .li.rBtn > a {
  border-radius: 0px!important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-active {
  transition-delay: 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#app {
  font-family: 'Titillium Web', Helvetica, Arial, sans-serif !important;
}
.modal-backdrop {
    width: 100%!important;
    height: 100%!important;
}
li.nav-item::marker {
  color: transparent;
}

@media only screen and (max-width: 480px) {
  #app {
    font-size: 10pt;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: table-cell;
    overflow: hidden!important;
  }
}
</style>

<script>
import Menu from "@/components/menu.vue";
import Footer from "@/components/footer.vue";

export default {
  components: {
    Menu,
    Footer
  },
  data(){
    return{
      course:'',
      currentReport:'',
      season:'',
      currentTitle:'',
      env:[]
    }
  },
      methods: {
        // Define method that will use the payload to update the data property
        setCurrentReport(payload) {
            this.currentReport = payload.currentReport
        },
            setSeason(payload) {
            this.season = payload.season
        },
            setCourse(payload) {
            this.course = payload.course
        },
        setCurrentTitle(payload) {
            this.currentTitle = payload.currentTitle
        },
    },
    mounted(){
      this.env = process.env
    }
};
</script>
