<template>
  <b-tab :title="this.TourName + liveOn">
    <div
      v-if="
        this.reportURL.indexOf('tmentry') !== -1 ||
          this.reportURL.indexOf('tmdraw') !== -1 ||
          this.reportURL.indexOf('tmscores') !== -1 ||
          this.reportURL.indexOf('tmresult') !== -1 ||
          this.reportURL.indexOf('tmoomt') !== -1
      "
    >
      <ReportTable
        :data="data"
        :previousData="previousData"
        :home="home"
        :title="title"
        :config="config"
        :updown="updown"
        :team="teamEvent"
        :match='match'
      />
      <b-nav small class="fullListings">
      <b-button v-if="title === 'Tournament Entries' || title === 'Final Result' || title.includes('Draw')" class='Flisting-btn-more left' @click="upDown"><span class="black"></span><span v-if="updown === false"> More</span><span v-if="updown === true"> Less</span></b-button>

        <b-nav-item
          v-if="title === 'Tournament Entries' || title === 'Final Result'"
          class="Flisting-btn"
          :to="{
            name: 'reports',
            query: { url: config.VUE_APP_API_FULL_LIST_URL, id: Year, code: courseCode, title: 'Tournament Entries' },
          }"
          ><span class="black">| <span class="FullListing">Full listing</span></span></b-nav-item
        >

        <b-nav-item
          v-if="title.includes('Draw')"
          class="left drawListing-btn"
          :to="{
            name: 'reports',
            query: { url: reportURL, id: Year, code: courseCode, title: 'Draw' },
          }"
          ><span class="black"> |</span> Draw <span class="black">|</span>
        </b-nav-item>
        <b-nav-item
          v-if="title.includes('Draw')"
          class="right drawListing-btn "
          :to="{
            name: 'reports',
            query: {
              url:
                config.VUE_APP_API_FULL_LIST_URL,
              id: Year,
              code: courseCode,
              title: 'Tournament Entries'
            },
          }"
        >
          Full Listing</b-nav-item
        >
        <b-nav-item
          v-if="title === 'Score'"
          class="lef Flisting-btnt"
          :to="{
            name: 'reports',
            query: { url: reportURL, id: Year, code: courseCode,title: 'Score' },
          }"
          >Score |</b-nav-item
        >
        <b-nav-item
          v-if="title === 'Score'"
          class="right"
          :to="{
            name: 'reports',
            query: {
              url:
                config.VUE_APP_API_FULL_LIST_URL,
              id: Year,
              code: courseCode,
              title: 'Tournament Entries'
            },
          }"
          >| Full Listing</b-nav-item
        >
      </b-nav>
    </div>
    <div v-else-if="this.reportURL.indexOf('tmlsmon') !== -1">
      <ReportTable
        :data="data"
        :previousData="previousData"
        :home="home"
        :title="title"
        :config="config"
        :updown="updown"
        :team="teamEvent"
      />
      <div>
        <b-nav small class="fullListings">
          <b-button class='Flisting-btn-more left' @click="upDown">|<span v-if="updown === false"> More</span><span v-if="updown === true"> Less</span></b-button>

          <b-nav-item
            class="left three Flisting-btn"
            :to="{
              name: 'reports',
              query: {
                url: reportURL,
                id: Year,
                code: courseCode,
                title: 'Live Score Monitor',
              },
            }"
            >| Live Score </b-nav-item
          >
          <b-nav-item
            class="center three Flisting-btn"
            :to="{
              name: 'reports',
              query: {
                url: reportURL,
                id: Year,
                code: courseCode,
                title: 'Live Score Monitor',
              },
            }"
            >| Hole By Hole </b-nav-item
          >
          <b-nav-item
            class="right three Flisting-btn"
            :to="{
              name: 'reports',
              query: {
                url:
                  config.VUE_APP_API_FULL_LIST_URL,
                id: Year,
                code: courseCode,
                title: 'Tournament Entries',
              },
            }"
            >| Full Listing |</b-nav-item
          >
        </b-nav>
      </div>
    </div>
  </b-tab>
</template>

<script>
import axios from "axios";
import ReportTable from '@/components/reportTable.vue'

export default {
  name: "homeTourOne",
  components: {
    ReportTable,
  },
  props: ["Year", "courseCode", "TourName", "currentReport", "reportURL", 'title', 'config', 'live', 'match'],

  data() {
    return {
      fields: [
        { isActive: true, key: "serial_no", label: "No." },
        { isActive: true, key: "playing_name", label: "Player Name" },
        { isActive: true, key: "", label: "Nat." },
        { isActive: true, key: "status_text", label: "Entry Status" },
        { isActive: true, key: "player_profile", label: "" }
      ],
      tableRow: "tableRow",
      data: [],
      tab: "tab",
      cell: "cell",
      home: true,
      updown: false,
    };
  },
  computed:{
        liveOn: function(message) {
      if (this.live === "Y") message = " - Livescoring is on";
      else message = ' - ' + this.title;
      return message;
    },
    teamEvent:function(team){
      var array = this.data
      if(array['team_scores'] !== undefined)
      team = 'Y'
      else
      team = 'N'
      return team
    },
  },
  methods: {
    updateStuff: function() {
      axios.get(this.currentReport).then(response => {
        this.data = response.data;
      });
    },
        upDown(){
      this.updown = !this.updown;
    }
  },
  created() {
    setInterval(this.updateStuff, 120000);
  },
  mounted() {
    axios.get(this.currentReport).then(response => {
      this.data = response.data;
    });
  }
};
</script>

<style scoped>
.FullListing {
  color: #519C1D;
}
.FullListing:hover {
  color: #263056;
}
::v-deep li.drawListing-btn > a {
  color: #337ab7 !important;
  padding-right: 10px;
  font-size: 20px!important;
  padding-top: 10px;
  padding-bottom: 10px;
}
::v-deep li.drawListing-btn{
width: auto!important;

}
::v-deep .cell {
  padding-top: 6px;
  padding-bottom: 6px;
}
.Flisting-btn{
  width: auto!important;
}
.Flisting-btn-more{
  color: #519C1D;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #dfe1e6!important;
  border: none!important;
  box-shadow: none!important;
  padding: 0.375rem 0.175rem;
}
.Flisting-btn-more:hover{
  border: none;
  color: #23527c;
}
li.Flisting-btn > a {
  color: #519C1D;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

li.Flisting-btn > a:hover {
  color: #23527c;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

ul.nav.fullListings.small {
    background: #dfe1e6;
    text-align: center;
    display: inline-flex;
    width: 100%;
    justify-content: center;
}
.tableRow {
  width: 100%;
}
.link {
  text-align: center;
}
.nav-link {
  padding: 0;
  font-weight: 400!important;
  
}
.nav-item{
  padding: 0.375rem 0.175rem;

}
.fields {
  color: white;
  background-color: #263056;
}
.topTab{
  padding: 0;
}
.black{
  color: black;
}
@media only screen and (max-width: 768px) {
.Flisting-btn>a{
  font-size: 12px!important;
}.Flisting-btn-more{
  font-size: 12px!important;
}
}
</style>
