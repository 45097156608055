<template>
  <div id="draw">
    <table>
      <thead>
        <tr>
          <template v-if="tablePick === 'Draw'">
            <th v-if="data.match_play === 'Y'">
              Group
            </th>
            <th v-else>
              Match
            </th>
            <th>
              Time
            </th>
            <th v-if="data.multi_course === 'Y'">
              Course
            </th>
            <th>
              Tee
            </th>
            <th>
              Player Name
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="(tabledata, index) in Eds">
          <tr @click.stop="rowClicked(index)" :key="index">
            <template v-if="tablePick === 'Draw' && data.match_play === 'N'">
              <td
                class="posnum drawPos"
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-if="tabledata.place == 1"
              >
                <span>
                  {{ tabledata.match }}
                </span>
              </td>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-else
                class="noPos"
              >
                <span>.</span>
              </td>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-if="tabledata.place == 1"
              >
                {{ tabledata.time }}
              </td>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-else
              ></td>
              <template v-if="data.multi_course === 'Y'">
                <td
                  :class="{
                    odd: tabledata.match % 2 === 1,
                    even: tabledata.match % 2 === 0,
                  }"
                  v-if="tabledata.place == 1"
                >
                  {{ tabledata.course }}
                </td>
                <td
                  :class="{
                    odd: tabledata.match % 2 === 1,
                    even: tabledata.match % 2 === 0,
                  }"
                  v-else
                ></td>
              </template>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-if="tabledata.place == 1"
              >
                {{ tabledata.tee }}
              </td>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-else
              ></td>
              <td
                class="drawName"
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
              >
                  {{ tabledata.name }}
              </td>
            </template>
            <template v-if="tablePick === 'Draw' && data.match_play === 'Y'">
              <td>
                {{ tabledata.match }}
              </td>
              <td>
                {{ tabledata.time }}
              </td>
              <td>
                {{ tabledata.tee }}
              </td>
              <td>
                <p>
                  {{ tabledata.name_1 }}
                </p>
                <p>
                  {{ tabledata.name_2 }}
                </p>
              </td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "draw",
  props: ["data", "previousData", "home", "title", "updown", 'config','match','team'],
  data() {
    return {
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: [],
    };
  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.tablePick === "Score" && this.clickedRow !== index) this.clickedRow = index;
      else this.clickedRow = -1;
    },
    playerCard: function(event) {
      var cardID = event.target.getAttribute("title");
      return (
        (this.cardID = cardID),
        axios
          .get(
            process.env.VUE_APP_TIC_BASE + process.env.VUE_APP_ID + '/' +
              this.data.season +
              "/" +
              this.data.season +
              "-" +
              this.data.code +
              "-cards-" +
              this.cardID +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then((response) => (this.playerCardData = response.data))
      );
    },
  },
  computed: {
    localTime: function (time, local){
      if(this.tablePick === 'Final'){
      time = this.data.last_updated_time.split(':')
      local = time[0]-1 + ':' + time[1]
      }
      else {
      time = this.data.last_scores_update_time.split(':')
      local = time[0]-1 + ':' + time[1] + ':' + time[2]
      }
      return local
    },
    tablePick: function(picked) {
    if (this.title.includes("Draw")) picked = "Draw";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    ud(way) {
      if (this.updown === false) way = 4;
      else way = 10;
      return way;
    },
    Eds(reportData) {
      var ud = this.ud;
      if (this.home === false) {
        if (this.title.includes("Draw"))
          reportData = this.data.draw.draw_entry;
        return reportData;
      } else {
        if (this.title.includes("Draw"))
          reportData = this.data.draw.draw_entry.slice(0, ud);
        return reportData;
      }
    },
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
    },
    immediate: true,
    deep: true,
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
span.nameTourn {
  color: #519c1f;
  font-size: 15pt;
  font-weight: 500;
}
td.exmptRow {
  color: #fff;
  background-color: #519c1f;
}
span.ScoreName {
  color: #519c1f;
  font-size: 15pt;
}
.drawPos{
  max-width: 100%!important;
  text-align: -webkit-center!important;
  margin-top: 0px!important;
  padding: 7px!important;
}
.title {
  text-align: center;
  background-color: #ffffff;
  color: #2F8D3E;
}
.title > h3 {
  padding-top: 8px;
  margin-bottom: 0px;
  font-size: 24px;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  position: sticky;
  top: 0;
  background-color: #2F8D3E;
  color: white!important;
      padding: 0.75rem;
    border-top: 1px solid #dee2e6;
}
table {
  width: 100%;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: center;
}
td:nth-child(3) {
  text-align: center;
}
.odd {
  background-color: white !important;
}
.even {
  background-color: #dfe1e6 !important;
}
tr:nth-child(even) {
  background-color: #dfe1e6;
}
td{
    padding: 0.75rem;
    vertical-align: top;
    
    font-size: 13pt;
}

.catD{
  background-color: #519c1f !important;
  color: #fff;
  font-size: 19px;
  padding: 6px;
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
  background-color: #2F8D3E;
  color: #fff;
}
td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
  background-color: #2F8D3E;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px !important;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}
@media only screen and (max-width: 768px) {
  .title > h3 {
    font-size: 13px !important;
  }
  .title > p {
    font-size: 12px !important;
  }
  th {
    font-size: 13px !important;
  }
  td {
    font-size: 13px !important;
  }
}
@media only screen and (max-width: 480px) {
  .mobHide{
    display: none;
  }
}

</style>
