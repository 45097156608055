var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"reportTable"}},[(_vm.home === false)?_c('div',{staticClass:"title"},[_c('h3',[_vm._v(_vm._s(_vm.data.full_name)+" - "+_vm._s(_vm.title))]),_c('p',[_vm._v(" "+_vm._s(_vm.data.course_dates)),(
          _vm.tablePick === 'Score' ||
            _vm.tablePick === 'Final' ||
            _vm.tablePick === 'Live'
        )?_c('span',[_c('br'),_vm._v(" Last Real Time Update - "+_vm._s(_vm.localTime)+" "),(_vm.tablePick === 'Score' || _vm.tablePick === 'Live')?_c('span',[_vm._v(_vm._s(_vm.data.last_scores_update_date))]):_vm._e(),(_vm.tablePick === 'Final')?_c('span',[_vm._v(_vm._s(_vm.data.last_updated_date))]):_vm._e(),_vm._v(" Ain Sokhna Time - "),(_vm.tablePick === 'Score' || _vm.tablePick === 'Live')?_c('span',[_vm._v(_vm._s(_vm.data.last_scores_update_time)+" "+_vm._s(_vm.data.last_scores_update_date))]):_vm._e(),(_vm.tablePick === 'Final')?_c('span',[_vm._v(_vm._s(_vm.data.last_updated_time)+" "+_vm._s(_vm.data.last_updated_date))]):_vm._e()]):_vm._e(),_c('br'),_vm._v(" Projected Finish Time - "+_vm._s(_vm.data.pft_time)+" ")]),(
        _vm.tablePick === 'Live' ||
          (_vm.tablePick === 'Score' && _vm.data.live_scoring === 'Y')
      )?_c('p',{staticStyle:{"text-align":"right"}},[_vm._v(" *Did not start from 1st Hole. ")]):_vm._e()]):_vm._e(),_c('table',[_c('thead'),_c('tbody',[(_vm.tablePick === 'Entries')?[_c('Entries',{attrs:{"data":_vm.data,"home":_vm.home,"title":_vm.title,"config":_vm.config,"team":_vm.team,"match":_vm.match,"code":_vm.code,"season":_vm.season}})]:_vm._e(),(_vm.tablePick === 'Draw')?[_c('Draw',{attrs:{"data":_vm.data,"home":_vm.home,"title":_vm.title,"config":_vm.config,"team":_vm.team,"match":_vm.match,"code":_vm.code,"season":_vm.season}})]:_vm._e(),(_vm.tablePick === 'Score' || _vm.tablePick === 'Live')?[_c('Score',{attrs:{"data":_vm.data,"home":_vm.home,"title":_vm.title,"config":_vm.config,"team":_vm.team,"match":_vm.match,"code":_vm.code,"season":_vm.season}})]:_vm._e(),(_vm.tablePick === 'Final')?[_c('Results',{attrs:{"data":_vm.data,"home":_vm.home,"title":_vm.title,"config":_vm.config,"team":_vm.team,"match":_vm.match,"code":_vm.code,"season":_vm.season}})]:_vm._e(),_vm._l((_vm.Eds),function(tabledata,index){return [_c('tr',{key:index,on:{"click":function($event){$event.stopPropagation();return _vm.rowClicked(index)}}}),(index === _vm.clickedRow)?_c('tr',{key:index + Math.random(),staticClass:"tr-kids",on:{"click":function($event){$event.stopPropagation();return _vm.rowClicked(_vm.rowClicked === -1)}}},[_c('td',{attrs:{"id":"reportCard","colspan":"11"}},[_c('PlayerReportCard',{attrs:{"data":_vm.playerCardData,"config":_vm.config,"actualRound":_vm.data.pft_round,"roundsPlayed":_vm.data.pft_round}})],1)]):_vm._e()]}),[(_vm.data.live_scoring_mess)?_c('div',{staticClass:"LiveScoreMess"},[_c('p',{staticClass:"LiveScoreMessPara"},[_vm._v(_vm._s(_vm.data.live_scoring_mess))])]):_vm._e()]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }