<template>
  <div class="footer">
    <b-row>
      <b-col lg='3' md='6' cols='6' class='colPad'>
        <h6 class="footLinks">
          IGT Golf
        </h6>
        <b-nav-item
          class="menuLink"
          :to="'/' + 'about'"
          >About</b-nav-item
        >
        <b-nav-item
          class="menuLink"
          :to="'/' + 'contact'"
          >Contact</b-nav-item
        >
        <b-nav-item class="menuLink" to="/news">News</b-nav-item>
        <b-nav-item
          class="menuLink"
          :to="'/' + 'tour-policies-2020'"
          >Tour Policies</b-nav-item
        >
        <b-nav-item
          class="menuLink"
          :to="'/' + 'membership'"
          >IGT Membership</b-nav-item
        >
        <b-nav-item
          class="menuLink"
          href="https://all.igtgolf.info/tic/tmtic.cgi"
          target="_blank"
          >Tour Info Centre</b-nav-item
        >
        <b-nav-item class="menuLink" :to="'/' + 'igt-newsletter-signup'" 
          >IGT Newsletter signup</b-nav-item 
        >
      </b-col>
      <b-col lg='2' md='6' cols='6' class='colPad'>
        <h6 class="footLinks">
          SOCIAL
        </h6>
        <b-nav-item
          class="menuLink"
          :href="'https://twitter.com/' + config.VUE_APP_TWITTER_NAME"
          >Twitter</b-nav-item
        >
        <b-nav-item
          class="menuLink"
          :href="'https://www.facebook.com/' + config.VUE_APP_FACEBOOK_NAME"
          >Facebook</b-nav-item
        >
        <b-nav-item
          class="menuLink"
          :href="'https://www.youtube.com/channel/' + config.VUE_APP_YOUTUBE_ID"
          >YouTube</b-nav-item
        >
      </b-col>
      <b-col lg='3' md='6' cols='6' class='colPad'>
        <h6 class="footLinks">
          TOURNAMENT
        </h6>
        <b-nav-item class="menuLink" to="/schedule">Schedule</b-nav-item>
      </b-col>
      <b-col lg='2' md='6' cols='6' class='colPad'>
        <h6 class="footLinks">
          PLAYERS
        </h6>
        <b-nav-item
          class="menuLink"
          :href="config.VUE_APP_PLAYER_MEMBER_URL"
          target="_blank"
          >Players Area</b-nav-item
        >
      </b-col>
      <b-col lg='2' md='6' cols='6' class='colPad'>
        <h6 class="footLinks">
          CONTACT US
        </h6>
        <p class="menuLink">H/O & Membership:</p>
        <p class="menuLink"><a href="tel:+27 783 300 440">+27 783 300 440</a></p>
        <p class="menuLink"><a href="mailto:admin@igtgolf.org">admin@igtgolf.org</a></p>
      </b-col>
    </b-row>
    <b-row :class="logoRow">
      <b-col v-for="(logos, index) in footerData" :key="index">
        <a :href="logos.acf.sponsor_url" target='_blank'>
        <b-img :class="logoImg" :src="logos.acf.image" />
        </a>
      </b-col>
    </b-row>
    <b-row :class="logoRowMobile">
      <b-col v-for="(logos, index) in footerData.slice(0, 5)" :key="index">
        <a :href="logos.acf.sponsor_url" target='_blank'>
        <b-img :class="logoImg" :src="logos.acf.image" />
        </a>
      </b-col>
    </b-row>
    <b-row :class="logoRowMobile">
      <b-col v-for="(logos, index) in footerData.slice(5, 10)" :key="index">
        <a :href="logos.acf.sponsor_url" target='_blank'>
        <b-img :class="logoImg" :src="logos.acf.image" />
        </a>
      </b-col>
    </b-row>
    <b-row class="ocs">
      <b-col class="center">
        <span >
        Copyright © IGT Golf {{new Date().getFullYear()}}. All Rights Reserved.
        </span>
      </b-col>
      <b-col>
        <b-media right-align>
          <template #aside>
            <a href="ocs-sport.com" target='_blank'>
            <b-img
              :class="ocsLogo"
              :src="config.VUE_APP_LOGO + 'images/misc/ocs-blue.svg'"
            /></a>
          </template>
          <p class="fr center">
            Powered By
          </p>
        </b-media>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Footer",
  props: ["currentReport", "season", "course", "config"],
  data() {
    return {
      footerData: [],
      logoImg: "logoImg",
      ocsLogo: "ocsLogo",
      logoRow: "logoRow",
      logoRowMobile: "logoRowMobile",
    };
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_WPAPI_URL +
          "wp/v2/Sponsors/" +
          "?randomadd=" +
          new Date().getTime()
      )
      .then((response) => (this.footerData = response.data));
  },
};
</script>

<style scoped>
.footer {
  background-color: #f1f1f1;
  padding-top: 2%;
  padding-left: 2%;
}
.row{
  margin-right: 0px;
}
.logoRowMobile {
  display: none;
}

.logoImg {
  width: 80%;
}

.ocsLogo {
  width: 30%;
  padding-bottom: 4%;
}

.logoRow {
  padding-top: 4%;
  text-align: center;
  margin-right: 0;
  padding-bottom: 30px;
}
.ocs {
  margin-right: 0;
  border-top: 1px solid #e1e1e1;
  margin-right: 15px;
  padding-top: 3em;
  padding-bottom: 3em;
}
.footLinks {
  font-weight: lighter;
  line-height: 1.2;
  text-transform: uppercase;
  padding-top: 10px;
  font-size: 1.5em;
  line-height: 54px;
  margin-bottom: 10px;
  border-top: 2px solid #519c1f;
}
.menuLink {
  font-size: 1em;
  line-height: 20px;
  color: #404040;
  display: block;
}
.menuLink > a {
  color: black;
  padding-left: 0;
}
.fr {
  float: right;
}
.center{
  margin-top: 50px;
}
@media only screen and (max-width: 480px) {
  .logoRow {
    display: none;
  }
  .logoRowMobile {
    display: flex;
    padding-top: 4%;
    padding-bottom: 4%;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
  }
  .ocs {
    margin-right: 0;
    margin-left: 0;
  }
  .ocsLogo{
        width: 22%;

    margin-top: 39px;
  }
  .colPad{
    padding-bottom: 25px;
  }
}
</style>
