<template>
  <div id="scores">
    <table>
      <thead>
        <tr>
          <template
            v-if="
              (tablePick === 'Score' && data.match_play === 'N') ||
                (tablePick === 'Live' && data.match_play === 'N')
            "
          >
            <th>
              Pos
            </th>
            <th>
              Player Name
            </th>
            <th class='mobHide'>
              Nat.
            </th>
            <th>
              Par
            </th>
            <template v-if="tablePick === 'Score' && this.title.includes('Scoreboard')">
              <th v-for="(i, roundIt) in range(1, roundsPlayed)" :key="roundIt">
                <span v-if="roundsPlayed === 1 && tablePick === 'Score'">
                  Score
                </span>
                <span v-else> R{{ i }} </span>
              </th>
              <th v-if="roundsPlayed > 1 && tablePick === 'Score'">
                Score
              </th>
              <th v-if="config.VUE_APP_PLAYER_ICON === 'Y'"></th>
            </template>
            <template v-if="tablePick === 'Live' || this.title.includes('Only')">
              <th>
                Thru
              </th>
            </template>
          </template>
          <template
            v-if="
              (tablePick === 'Score' && data.match_play === 'Y') ||
                (tablePick === 'Live' && data.match_play === 'Y')
            "
          >
            <th>
              Name
            </th>
            <th>
              Score
            </th>
            <th>
              Thru.
            </th>
            <th>
              Score
            </th>
            <th>
              Name
            </th>
          </template>
          <template> </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="(tabledata, index) in Eds">
          <tr @click.stop="rowClicked(index)" :key="index">
            <template
              v-if="
                (tablePick === 'Score' && data.match_play === 'N') ||
                  (tablePick === 'Live' && data.match_play === 'N')
              "
            >
              <td class="cut" colspan="11" v-if="tabledata.pos === 'CUT'">
                {{ tabledata.cut_round }}
              </td>
              <td
                class="posnum"
                v-else-if="
                  index !== 0 &&
                    (tabledata.tied !== '=' ||
                      Eds[index - 1].pos !== tabledata.pos)
                "
              >
                <span>
                  {{ tabledata.pos }}
                </span>
              </td>
              <td class="posnum" v-else-if="index === 0">
                <span>
                  {{ tabledata.pos }}
                </span>
              </td>
              <td v-else class="noPos"><span>.</span></td>

              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    team === 'N'
                "
                @click="playerCard"
                :title="tabledata.member_no"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              >
                  {{ tabledata.name }}
              </td>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    team === 'Y'
                "
                @click="playerCard"
                :title="tabledata.member_no"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              >
                {{ tabledata.name }}
                <span v-if="tabledata.sponsored === 'Y'"
                  ><b-img :src="config.VUE_APP_TITLEIST"></b-img
                ></span>
              </td>
              <td class="mobHide" v-if="tabledata.pos !== 'CUT'">
                <span v-if="tabledata.nationality === ''"></span>
                <span v-else>
                  <img
                    class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + tabledata.nationality)
                        | lowercase
                    "
                  />
                </span>
              </td>
              <td v-if="tabledata.vspar < 0" class="up">
                {{ tabledata.vspar }}
              </td>
              <td v-else-if="tabledata.vspar > 0" class="down">
                {{ tabledata.vspar }}
              </td>
              <td v-else-if="tabledata.pos !== 'CUT'">
                {{ tabledata.vspar }}
              </td>
              <template v-if="tablePick === 'Score' && title.includes('Scoreboard') && tabledata.pos !== 'CUT'">
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                >
                  <span
                    v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                    class="up"
                    >{{ tabledata.score }}</span
                  >
                  <span
                    v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                    class="down"
                    >{{ tabledata.score }}</span
                  >
                  <span v-else-if="roundsPlayed === 1">{{
                    tabledata.score
                  }}</span>
                  <span v-else-if="tabledata['vspar_R' + i] < 0" :class="up">{{
                    tabledata["score_R" + i]
                  }}</span>
                  <span
                    v-else-if="tabledata['vspar_R' + i] > 0"
                    :class="down"
                    >{{ tabledata["score_R" + i] }}</span
                  >
                  <span v-else>{{ tabledata["score_R" + i] }}</span>
                </td>
                <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up">
                  {{ tabledata.score }}
                </td>
                <td
                  v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                  class="down"
                >
                  {{ tabledata.score }}
                </td>
                <td v-else-if="roundsPlayed > 1">
                  {{ tabledata.score }}
                </td>
              </template>
              <template v-if="tablePick === 'Live' || title.includes('Only') && tabledata.pos !== 'CUT'">
                <td v-if="tabledata.vspar < 0" class="up">
                  <span v-if="tabledata.holes === ''" style="color:black;">
                    {{ tabledata["time_R" + data.pft_round] }}
                  </span>
                  <span v-if="tabledata.holes < 18">
                    {{ tabledata.holes
                    }}<span v-if="tabledata['tee_R' + roundsPlayed] === '10'"
                      >*</span
                    >
                  </span>
                  <span v-else>
                    F<span v-if="tabledata['tee_R' + roundsPlayed] === '10'"
                      >*</span
                    >
                  </span>
                </td>
                <td v-else-if="tabledata.vspar > 0" class="down">
                  <span v-if="tabledata.holes < 18">
                    {{ tabledata.holes
                    }}<span v-if="tabledata['tee_R' + roundsPlayed] === '10'"
                      >*</span
                    >
                  </span>
                  <span v-else>
                    F<span v-if="tabledata['tee_R' + roundsPlayed] === '10'"
                      >*</span
                    >
                  </span>
                </td>
                <td v-else>
                  <span v-if="tabledata.holes < 18">
                    {{ tabledata.holes
                    }}<span v-if="tabledata['tee_R' + roundsPlayed] === '10'"
                      >*</span
                    >
                  </span>
                  <span v-else>
                    F<span v-if="tabledata['tee_R' + roundsPlayed] === '10'"
                      >*</span
                    >
                  </span>
                </td>
              </template>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF' &&
                    tabledata.pos !== 'CUT' &&
                    config.VUE_APP_PLAYER_ICON === 'Y'
                "
              >
                <a :href="'/playerprofile/' + tabledata.member_no">
                  <font-awesome-icon
                    style="color:#337ab7!important;"
                    id="user"
                    class="icon"
                    :icon="['fa', 'user']"
                  />
                </a>
              </td>
            </template>
            <template
              v-if="
                (tablePick === 'Score' && data.match_play === 'Y') ||
                  (tablePick === 'Final' && data.match_play === 'Y')
              "
            >
              <td
                :title="tabledata.member_no_1"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                  win: tabledata.status_1 == 'W',
                }"
              >
                {{ tabledata.initials_1 }} {{ tabledata.surname_1 }}
              </td>
              <td v-if="tabledata.status_1 === 'W'" class="win">
                {{ tabledata.full_score_1 }}
              </td>
              <td v-else></td>
              <td>
                <span v-if="tabledata.holes === ''">
                  {{ tabledata.time }}
                </span>
                <span v-else-if="tabledata.holes < 18">
                  {{ tabledata.holes
                  }}<span v-if="tabledata.tee_R1 > 1">*</span>
                </span>
                <span v-else>
                  F<span v-if="tabledata.tee_R1 > 1">*</span>
                </span>
              </td>
              <td v-if="tabledata.status_2 === 'W'" class="win">
                {{ tabledata.full_score_2 }}
              </td>
              <td v-else></td>
              <td
                :title="tabledata.member_no_2"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                  win: tabledata.status_2 == 'W',
                }"
              >
                {{ tabledata.initials_2 }} {{ tabledata.surname_2 }}
              </td>
            </template>
          </tr>
          <tr
            @click.stop="rowClicked(rowClicked === -1)"
            v-if="index === clickedRow"
            class="tr-kids"
            :key="index + Math.random()"
          >
            <td id="reportCard" colspan="11">
              <PlayerReportCard
                :data="playerCardData"
                :config="config"
                :actualRound="data.pft_round"
                :roundsPlayed="data.pft_round"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import PlayerReportCard from "@/components/playerReportCard.vue";
export default {
  name: "scores",
  props: ["data", "previousData", "home", "title", "updown", 'config','match','team'],
  components: { PlayerReportCard },
  data() {
    return {
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: [],
    };
  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.tablePick === "Score" && this.clickedRow !== index) this.clickedRow = index;
      else this.clickedRow = -1;
    },
    playerCard: function(event) {
      var cardID = event.target.getAttribute("title");
      return (
        (this.cardID = cardID),
        axios
          .get(
            process.env.VUE_APP_TIC_BASE + process.env.VUE_APP_ID + '/' +
              this.data.season +
              "/" +
              this.data.season +
              "-" +
              this.data.code +
              "-cards-" +
              this.cardID +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then((response) => (this.playerCardData = response.data))
      );
    },
  },
  computed: {
    localTime: function (time, local){
      if(this.tablePick === 'Final'){
      time = this.data.last_updated_time.split(':')
      local = time[0]-1 + ':' + time[1]
      }
      else {
      time = this.data.last_scores_update_time.split(':')
      local = time[0]-1 + ':' + time[1] + ':' + time[2]
      }
      return local
    },
    tablePick: function(picked) {
     if (
        this.title.includes("Scores") ||
        this.title.includes("Scoreboard")
      )
        picked = "Score";
      else if (this.title.includes("Live")) picked = "Live";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    ud(way) {
      if (this.updown === false) way = 4;
      else way = 5;
      return way;
    },
    Eds(reportData) {
      var ud = this.ud;
      if (this.home === false) {
        if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard") ||
          this.title.includes("Live")
        )
          reportData = this.data.scores.scores_entry;
        return reportData;
      } else {
        if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard") ||
          this.title.includes("Live")
        )
          reportData = this.data.scores.scores_entry.slice(0, ud);
        return reportData;
      }
    },
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
    },
    immediate: true,
    deep: true,
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
span.nameTourn {
  color: #519c1f;
  font-size: 15pt;
  font-weight: 500;
}
td.exmptRow {
  color: #fff;
  background-color: #519c1f;
}
span.ScoreName {
  color: #519c1f;
  font-size: 15pt;
}
.drawPos{
  max-width: 100%!important;
  text-align: -webkit-center!important;
  margin-top: 0px!important;
  padding: 7px!important;
}
.title {
  text-align: center;
  background-color: #ffffff;
  color: #2F8D3E;
}
.title > h3 {
  padding-top: 8px;
  margin-bottom: 0px;
  font-size: 24px;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  position: sticky;
  top: 0;
  background-color: #2F8D3E;
  color: white!important;
      padding: 0.75rem;
    border-top: 1px solid #dee2e6;
}
table {
  width: 100%;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: center;
}
td:nth-child(3) {
  text-align: center;
}
.odd {
  background-color: white !important;
}
.even {
  background-color: #dfe1e6 !important;
}
tr:nth-child(even) {
  background-color: #dfe1e6;
}
td{
    padding: 0.75rem;
    vertical-align: top;
    
    font-size: 13pt;
}

.catD{
  background-color: #519c1f !important;
  color: #fff;
  font-size: 19px;
  padding: 6px;
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
  background-color: #2F8D3E;
  color: #fff;
}
td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
  background-color: #2F8D3E;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px !important;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}
@media only screen and (max-width: 768px) {
  .title > h3 {
    font-size: 13px !important;
  }
  .title > p {
    font-size: 12px !important;
  }
  th {
    font-size: 13px !important;
  }
  td {
    font-size: 13px !important;
  }
}
@media only screen and (max-width: 480px) {
  .mobHide{
    display: none;
  }
}

</style>
