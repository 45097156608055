<template>
  <div class="home">
    <div id="table">
      <TableHome
        :Year="season"
        :courseCode="course"
        :TourName="tm_params.short_name"
        :currentReport="reportTitle"
        :reportURL="currentReport"
        :title="currentTitle"
        :config='config'
        :live="tm_params.live_scoring"
        :match="tm_params.match_play"
      />
    </div>
    <b-row class="sections">
      <b-col id="reports" lg="4" md="12" cols="12">
        <h3 class="titleHead">
          Tournament Reports
        </h3>
        <HomeReportSelect
          :seasons="tm_params.seasons.seasons_entry"
          :defaultID="season"
          :defaultCourse="tm_params.short_name"
          :defaultReports="reports"
        />
      </b-col>
      <b-col id="instagram" lg="4" md="6" cols="12">
        <h3 class="titleHead">
          Facebook Feed
        </h3>
        <FaceBook :config="config"/>
      </b-col>
      <b-col id="twitter" lg="4" md="6" cols="12">
        <h3 class="titleHead">
          Latest Tweets
        </h3>
        <TwitterFeed :config='config'/>
      </b-col>
    </b-row>
    <b-row class="sections">
      <b-col id="instagram" lg="12" md="12" cols="12">
        <div>
          <h3 class="titleHead">
            Latest from YouTube
          </h3>
          <YouTube />
        </div>
      </b-col>
    </b-row>
    <b-row class="sections">
      <b-col>
        <div id="posts" class="HomeNews">
          <Posts :home="home" :config='config'/>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import TableHome from "@/components/tableHome.vue";
// import Slider from "@/components/slider.vue";
import TwitterFeed from "@/components/twitterFeed.vue";
import FaceBook from "@/components/facebookFeed.vue";
import Posts from "@/components/posts.vue";
import HomeReportSelect from "@/components/homeReportSelect.vue";
import YouTube from "@/components/youTube.vue";

export default {
  name: "home",
  props: ["course", "season", "currentReport", "currentTitle", "config"],
  components: {
    TableHome,
    // Slider,
    TwitterFeed,
    Posts,
    FaceBook,
    HomeReportSelect,
    YouTube,
  },
  data: function() {
    return {
      title: [],
      description: [],
      tm_params: [],
      home: true,
    };
  },
  computed: {
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          process.env.VUE_APP_TIC_BASE +
          process.env.VUE_APP_ID +
          "/" +
          this.season +
          "/" +
          this.season +
          "-" +
          this.course +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscore") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              process.env.VUE_APP_TIC_BASE +
              process.env.VUE_APP_ID +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              process.env.VUE_APP_TIC_BASE +
              process.env.VUE_APP_ID +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              process.env.VUE_APP_TIC_BASE +
              process.env.VUE_APP_ID +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmstats") !== -1) {
          {
            url =
              process.env.VUE_APP_TIC_BASE +
              process.env.VUE_APP_ID +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmcrsts") !== -1) {
          {
            url =
              process.env.VUE_APP_TIC_BASE +
              process.env.VUE_APP_ID +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_ID +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_ID +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_ID +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    },
  },
  mounted() {
    axios 
      .get(
        process.env.VUE_APP_TIC_BASE +
          process.env.VUE_APP_ID +
          "/tmticx?randomadd=" +
          new Date().getTime()
      ) 
      .then((response) => {
        this.tm_params = response.data;
      });
  },
};
</script>
<style scoped>

.HomeNews {
  margin-top: 5em;
}

.home {
  background-color: #f1f1f1;
}

.sections >div {
  padding: 0 40px;
}
.row{
  margin-right: 0px;
}
.titleHead {
  color: #2f8d3e;
  padding-bottom: 15px;
  font-size: 13pt;
}
@media only screen and (max-width: 480px) {
  #twitter {
    flex-basis: auto;
  }
  #reports {
    flex-basis: auto;
  }
  .titleHead {
    text-align: left;
  }
}
</style>
