<template>
  <b-container>
    <div id="quickReport">
      <b-row class="reportZero">
        <b-col class="drop ">
          <b-nav card-header pills>
            <b-dropdown :text="this.defaultID + ' Season'">
              <b-dropdown-item
                v-on:click="changeYear"
                v-for="(seasons, index) in seasons.slice().reverse()"
                :key="index"
                :title="seasons.code"
                :year="seasons.code"
                >{{ seasons.desc }}</b-dropdown-item
              >
            </b-dropdown>
          </b-nav>
        </b-col>
      </b-row>
      <b-row class="reportOne">
        <b-col class="drop ">
          <b-nav card-header pills>
            <b-dropdown :text="this.defaultCourse">
              <b-dropdown-item
                v-on:click="changeCourse"
                v-for="(courseList, index) in courses"
                :key="index"
                :title="courseList.code"
                :value="courseList.full_name"
                >{{ courseList.short_name }}</b-dropdown-item
              >
            </b-dropdown>
          </b-nav>
        </b-col>
      </b-row>
      <b-row class="reportTwo">
        <b-col class="drop ">
          <b-nav card-header pills>
            <b-dropdown :text="this.reportName">
              <b-dropdown-item
                v-on:click="changeReport"
                v-for="(reportList, index) in reports"
                :key="index"
                :title="reportList.report_title"
                :value="reportList.report_url"
                >{{ reportList.report_title }}</b-dropdown-item
              >
            </b-dropdown>
          </b-nav>
        </b-col>
      </b-row>
      <b-row class="reportThree">
        <b-nav-item
          class="rBtn"
          active
          :to="{
            name: 'reports',
            query: { url: reportURL, id: defaultID, code: courseCode, title: reportName }
          }"
          ><span class="reportB">View Report</span></b-nav-item
        >
      </b-row>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
export default {
  name: "homeReportSelect",
  props: ["seasons", "defaultID", "defaultCourse", "defaultReports"],
  data() {
    return {
      courses: [],
      reports: [],
      courseCode: [],
      reportName: 'Select Report',
      reportURL: []
    };
  },
  methods: {
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
      return (
        (this.defaultID = id),
        axios
          .get(
          process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_ID +
            "/" +
              this.defaultID +
              "/tmticx?randomadd=" +
              new Date().getTime()
          )
          .then(
            response =>
              (this.courses = response.data.tournaments.tournaments_entry)
          )
      );
    },
    changeCourse: function(event) {
      var courseCode = event.target.getAttribute("title");
      var courseName = event.target.getAttribute("value");
      return (
        ((this.defaultCourse = courseName), (this.courseCode = courseCode)),
        axios
          .get(
          process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_ID +
            "/" +
              this.defaultID +
              "/" +
              this.defaultID +
              "-" +
              this.courseCode +
              "-tmticx.json?randomadd=" +
              new Date().getTime()
          )
          .then(
            response => (this.reports = response.data.reports.reports_entry)
          )
      );
    },
    changeReport: function(event) {
      var reportName = event.target.getAttribute("title");
      var reportURL = event.target.getAttribute("value");
      return (this.reportName = reportName), (this.reportURL = reportURL);
    }
  }
};
</script>


<style scoped>
.reportZero {
  margin-bottom: 5px;
}
.reportOne {
  margin-bottom: 5px;
}
.reportTwo {
  margin-bottom: 5px;
}
.reportThree {
  margin-bottom: 5px;
}
.viewReport {
  color: #ffffff;
}
.homeReport-list {
  background-color: #dfe1e6;
  padding: 31px;
} 
li.rBtn > a {
  border-radius: 0rem;
}
::v-deep .btn-secondary {
  color: #000;
  background-color: #ffffff;
  border-color: #6c757d;
  border-radius: 0rem;
  text-align: left;
}
#quickReport {
  /* padding-left: 5%;
  background-color: lightgrey; */
}
::v-deep .dropdown {
  width: 100%;
}

a.nav-link.active {
  margin-top: -20%;
  background-color: #2F8D3E;
  color: white;
}
.rBtn {
  padding-bottom: 2%;
  margin-top: 2em;
  padding-left: 6px;
}

.title {
  padding-top: 2%;
}
::v-deep .dropdown-toggle::after{

    float: right;
    margin-top: 10px;

}
li{
  list-style: none;
}
@media only screen and (max-width: 480px) {
  ::v-deep .btn {
    font-size: 0.7rem;
  }
}
</style>