<template>
  <div class="tableHome">
    <b-tabs>
      <HomeTabOne
        :Year="Year"
        :courseCode="courseCode"
        :TourName="TourName"
        :currentReport="currentReport"
        :reportURL="reportURL"
        :title='title'
        :config="config"
        :live="live"
        :match='match'
      />
      <Oom 
        :Year="Year"
        :config='config'
      />
    </b-tabs>
  </div>
</template>

<script>
import Oom from "@/components/oom.vue";
import HomeTabOne from "@/components/homeTabOne.vue";

export default {
  name: "tableHome",
  props: ["Year", "courseCode", "TourName", "currentReport", "reportURL", 'title', 'config', 'live', 'match'],
  components: {
    Oom,
    HomeTabOne
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .nav-tabs .nav-link {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}
::v-deep .nav-link.active {
  color: white !important;
  background-color: #2F8D3E !important;
  width: 100%;
  border-color: #2F8D3E !important;
  height: 100%;
}

::v-deep .nav-link {
  color: black;
  width: 100%;
  font-size: 18pt;
}

::v-deep li.nav-item {
  width: 50%;
  text-align: center;
}
.tableHome {
  padding-bottom: 2%;
}
.mt {
  margin-top: 0 !important;
}
@media only screen and (max-width: 480px) {
 ::v-deep .nav-link{
    font-size: 1rem;
  }
}
</style>
