import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueMeta from "vue-meta";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import JQuery from "jquery";
import VueInstagram from "vue-instagram";
import VueGtag from "vue-gtag";

library.add(
  faFacebook,
  faTwitter,
  faYoutube
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueInstagram);
Vue.use(VueGtag, {
  config: { id: "UA-69028219-1" },
  includes: [
    { id: 'UA-69028219-1' }
  ]
}, router);

new Vue({
  router,
  store,
  axios,
  JQuery,
  render: h => h(App)
}).$mount("#app");
