<template>
  <div id='twitter'>
    <a class="twitter-timeline" data-height='400' :href="'https://twitter.com/'+config.VUE_APP_TWITTER_NAME+'?ref_src=twsrc%5Etfw'">Tweets by igtgolf</a> <script async type="application/javascript" src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  </div>
</template>

<script>
export default {
  name: "twitterFeed",
  props:['config']
};
</script>

<style scoped>
._2p3a {
  min-width: 100%!important;
  width: 100%!important;
}
#twitter {
  height: 450px;
}
.timeline-Tweet-media {
  display: none !important;
}
::v-deep .fb_iframe_widget {
  display: inline-block;
  position: relative;
  float: left;
  width: 100%;
}
::v-deep .fb_iframe_widget span {
  display: inline-block;
  position: relative;
  text-align: justify;
  width: 100%!important;
}

::v-deep span{ 
  width: 100%!important;
}
::v-deep iframe{ 
  width: 100%!important;
}
::v-deep ._2p3a {
  width: 100%!important;
}
::v-deep .uiScaledImageContainer.fbStoryAttachmentImage {
  width: 100%;
  display: contents;
}
#u_0_0_Ef > div > div._2p3a {
  width: 100%;
}
</style>
